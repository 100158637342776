import { CustomizedTitle } from "./styles";

interface TitleProps {
  children?: React.ReactNode;
}

const Title = ({ children }: TitleProps) => {
  return (
  <CustomizedTitle>{ children }</CustomizedTitle>
  )
}

export default Title;
