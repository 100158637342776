import { SyntheticEvent, useState } from 'react'
import * as S from './styles'
import { InputStyled, InputBox, EntryButton } from 'src/styles'

import { auth } from '../../contexts/config'
import { sendPasswordResetEmail } from 'firebase/auth'

import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

const ResetPassword = () => {
  const [email, setEmail] = useState('')

  const [notification, setNotification] = useState<{
    open: boolean
    message: string
    severity: 'success' | 'error' | 'info' | 'warning'
  }>({
    open: false,
    message: '',
    severity: 'success'
  })

  const handleCloseNotification = (
    _event: Event | SyntheticEvent<unknown, Event>,
    reason: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setNotification({ ...notification, open: false })
  }

  const handleResetPassword = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()
    if (email) {
      try {
        const data = await sendPasswordResetEmail(auth, email)
        console.log(data)
        console.log(auth)
        setNotification({
          open: true,
          message: 'E-mail enviado com sucesso',
          severity: 'success'
        })
      } catch (error) {
        console.log(error)
        setNotification({
          open: true,
          message: 'E-mail não encontrado',
          severity: 'error'
        })
      }
    }
  }

  return (
    <>
      <S.Back to="/login">Voltar</S.Back>
      <S.Container>
        <S.FormRegister>
          <S.Register>Recuperar Senha</S.Register>
          <InputBox>
            <InputStyled
              id="email"
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Digite o e-mail cadastrado"
              autoComplete="email"
              required
            />
          </InputBox>
          <EntryButton onClick={handleResetPassword} type="submit">
            Enviar
          </EntryButton>
        </S.FormRegister>
      </S.Container>
      <Snackbar
        open={notification.open}
        autoHideDuration={3000}
        onClose={(event, reason) => handleCloseNotification(event, reason)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() =>
            handleCloseNotification(
              {} as React.SyntheticEvent<unknown, Event>,
              ''
            )
          }
          severity={notification.severity}
        >
          {notification.message}
        </MuiAlert>
      </Snackbar>
    </>
  )
}

export default ResetPassword
