import styled from 'styled-components'

export const CustomizedTitle = styled.h1`
  font-size: 56px;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    font-size: 32px;
    text-align: center;
  }
`
