import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../Auth/AuthContext'
import { Session } from 'src/Types/sessions'
import SessionContext from './SessionContext'
import Api from 'src/providers/Api'
import { Setup } from 'src/Types/setup'
import SetupContext from '../../contexts/Setups/SetupContext'
import { useDate } from '../../contexts/Date/DateContext'

interface SessionProviderProps {
  children: React.ReactNode
  setups: Setup[]
}

export const SessionProvider: React.FC<SessionProviderProps> = ({
  children,
  setups
}: SessionProviderProps) => {
  const auth = useContext(AuthContext)
  const businessId = useContext(SetupContext)?.businessId
  const { selectedDate } = useDate()
  const [sessions, setSessions] = useState<Session[]>([])

  const accessToken = auth.accessToken

  const fetchSessions = async (dates: string[] = []) => {
    try {
      const params = dates.map((date) => `dates=${date}`).join('&')
      const URL = `/sessions?${params}&businessId=${businessId}`

      const response = await Api.get(URL, {
        headers: {
          Authorization: `${accessToken}`,
          'Content-Type': 'application/json'
        }
      })

      if (response.data) {
        const data = Array.isArray(response.data.data)
          ? response.data.data
          : [response.data.data]
        setSessions(data)
      } else {
        console.error('Response data is invalid:', response.data)
        setSessions([])
      }
    } catch (error) {
      console.error('Failed to fetch sessions', error)
      setSessions([])
    }
  }

  useEffect(() => {
    const dateStr = selectedDate.toISOString().split('T')[0] // Format date to 'yyyy-MM-dd'
    fetchSessions([dateStr])
  }, [selectedDate, setups])

  return (
    <SessionContext.Provider value={{ sessions, fetchSessions }}>
      {children}
    </SessionContext.Provider>
  )
}
