import { createContext } from 'react'
import { Session } from 'src/Types/sessions'

interface SessionContextProps {
  sessions: Session[]
  fetchSessions: (dates?: string[]) => Promise<void>
}

const SessionContext = createContext<SessionContextProps>({
  sessions: [],
  fetchSessions: async () => {}
})

export default SessionContext
