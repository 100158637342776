import { createContext, useContext } from 'react'
import { Member } from '../../Types/members'

interface MemberContextProps {
  members: Member[]
  fetchMembers: () => void
  addMember: (member: Member) => void
  removeMember: (id: string) => void
}

const MembersContext = createContext<MemberContextProps | undefined>(undefined)

export const useMemberContext = (): MemberContextProps => {
  const context = useContext(MembersContext)
  if (!context) {
    throw new Error('useMemberContext must be used within a MembersProvider')
  }
  return context
}

export default MembersContext
