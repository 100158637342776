import background from '../../assets/mainBackground.png'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import variables from 'src/styles/variables'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: url(${background}) no-repeat;
  background-size: cover;
  background-position: center;
  overflow-y: hidden;
`

export const Register = styled.h1`
  font-size: 36px;
  text-align: center;
`

export const Back = styled(Link)`
  position: absolute;
  top: 20px;
  left: 20px;
  width: 80px;
  height: 40px;
  background: transparent;
  border: 2px solid ${variables.white};
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  color: ${variables.white};
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  padding-top: 8px;
  diplay: flex;
  justify-content: center;

  &:hover {
    background: ${variables.white};
    color: ${variables.purple};
  }

  @media (max-width: 490px) {
    display: block;
    width: 60px;
    height: 35px;
    font-size: 12px;
    background: ${variables.purple};
  }
`
export const FormRegister = styled.form`
  width: 420px;
  height: 100%;
  background: ${variables.purple};
  border: 5px solid rgba(255, 255, 255, 0.2);
  bacground-filter: blur(50px);
  color: ${variables.white};
  padding: 30px 40px;
  border-radius: 30px;

  @media (max-width: 800px) {
    width: 80%;
    padding: 30px 20px;
  }

  @media (max-width: 375px) {
    padding: 30px 10px;
    width: 70%;
  }
`
