import Header from 'src/components/Header'
import AddMember from 'src/components/AddMember'
import MembersList from 'src/components/MembersList'
import { MemberProvider } from 'src/contexts/Members/MembersProvider'

const Members = () => {
  return (
    <>
      <Header />
      <div>
        <MemberProvider>
          <AddMember />
          <MembersList />
        </MemberProvider>
      </div>
    </>
  )
}

export default Members
