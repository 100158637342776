import { useNavigate } from 'react-router-dom'
import chair from '../../assets/chair.png'
import * as S from './styles'
import Button from 'src/components/Button'
import Title from 'src/components/Title/Index'
import Subtitle from 'src/components/Subtitle'
import { MainContainer } from 'src/styles'

const MainPage = () => {
  const navigate = useNavigate()

  const handleLogin = () => {
    navigate('/login')
  }

  return (
    <>
      <MainContainer>
        <S.Image>
          <S.Chair src={chair} alt="" />
        </S.Image>
        <S.Text>
          <Title>Agenda Completa</Title>
          <Subtitle>Gerencie suas aulas de forma simples!</Subtitle>
          <Button onClick={handleLogin}>Entrar</Button>
        </S.Text>
      </MainContainer>
    </>
  )
}

export default MainPage
