import { Route, Routes } from 'react-router-dom'
import { RequireAuth } from 'src/contexts/Auth/RequireAuth'
import Dashboard from 'src/pages/Dashboard'
import Login from 'src/pages/Login'
import MainPage from 'src/pages/MainPage'
import Members from 'src/pages/Members'
import Register from 'src/pages/Register'
import ResetPassword from 'src/pages/ResetPassword'
import SchedulePage from 'src/pages/SchedulePage'
import Setup from 'src/pages/Setup'

export function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="resetPassword" element={<ResetPassword />} />
      <Route
        path="/dashboard"
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      />
      <Route
        path="/schedule"
        element={
          <RequireAuth>
            <SchedulePage />
          </RequireAuth>
        }
      />
      <Route
        path="/members"
        element={
          <RequireAuth>
            <Members />
          </RequireAuth>
        }
      />
      <Route
        path="/setup"
        element={
          <RequireAuth>
            <Setup />
          </RequireAuth>
        }
      />
    </Routes>
  )
}
