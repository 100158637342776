import Header from 'src/components/Header'
import Schedule from 'src/components/Schedule'
import * as S from './styles'
import { AutoCompleteName } from 'src/components/AutoCompleteName'
import { MemberProvider } from 'src/contexts/Members/MembersProvider'
import { SessionProvider } from 'src/contexts/Sessions/SessionProvider'
import { useSetupContext } from '../../contexts/Setups/SetupContext'
import { SetupProvider } from '../../contexts/Setups/SetupProvider'
import { DateProvider } from 'src/contexts/Date/DateContext'

const SchedulePage = () => {
  return (
    <>
      <Header />
      <S.Container>
        <MemberProvider>
          <AutoCompleteName />
        </MemberProvider>
      </S.Container>
      <DateProvider>
        <SetupProvider>
          <SessionComponentWithSetup />
        </SetupProvider>
      </DateProvider>
    </>
  )
}

const SessionComponentWithSetup: React.FC = () => {
  const { setups } = useSetupContext()

  return (
    <SessionProvider setups={setups}>
      <Schedule />
    </SessionProvider>
  )
}

export default SchedulePage
