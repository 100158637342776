import variables from 'src/styles/variables'
import styled from 'styled-components'

export const EntryButton = styled.button`
  background-color: ${variables.purple};
  color: ${variables.white};
  font-size: 24px;
  font-weight: 700;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: 0.3s;
  width: 100%;
  height: 50px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${variables.white};
    color: ${variables.purple};
    background: ${variables.white};
    border: 2px solid ${variables.purple};
  }

  @media (max-width: 768px) {
    font-size: 16px;
    width: 100%;
    margin-bottom: 5px;
  }
`
