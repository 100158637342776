import variables from 'src/styles/variables'
import styled from 'styled-components'

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 10px;
  border: 1px solid #34495e;
  border-radius: 8px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #c0c0c0;
  width: 160px;
`

export const DetailsButton = styled.button`
  margin-top: 10px;
  background-color: ${variables.purple};
  color: ${variables.white};
  border: none;
  border-radius: 8px;
  padding: 5px 10px;
  cursor: pointer;
  transition: 0.3s;
  width: 100%;
  font-weight: bold;
  &:hover {
    background-color: ${variables.whiteSmoke};
    color: ${variables.purple};
  }
`

export const Aluno = styled.span`
  font-weight: bold;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const DetailsContainer = styled.div`
  padding: 20px;
  background-color: ${variables.whiteSmoke};
  border-radius: 8px;
`

export const DetailItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background-color: ${variables.white};
  border: 1px solid #ddd;
  border-radius: 4px;
`

export const ActionButton = styled.button`
  background-color: ${variables.purple};
  color: ${variables.white};
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
    color: ${variables.purple};
    font-weight: bold;
  }
`

export const ClassDetailsString = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 18px;
    font-weight: bold;
  }
`
