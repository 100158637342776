import styled, { createGlobalStyle } from 'styled-components'
import mainBackground from '../assets/mainBackground.png'
import variables from './variables'

const GlobalStyle = createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;


  body {
    background-color: ${variables.whiteSmoke};
    font-family: 'DM Sans', sans-serif;
  }
}
`
export const MainContainer = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  height: 100vh;
  overflow-y: scroll;
  background: url(${mainBackground}) no-repeat;
  background-size: cover;
  background-position: center;

  @media (max-width: 800px) {
    padding: 0 20px;
    flex-direction: column;
  }

  @media (max-width: 490px) {
    padding: 0 10px;
  }
`

export const FormContainer = styled.form`
  width: 420px;
  height: 100%;
  background: ${variables.purple};
  border: 5px solid rgba(255, 255, 255, 0.2);
  bacground-filter: blur(50px);
  color: ${variables.white};
  padding: 30px 40px;
  border-radius: 30px;

  @media (max-width: 800px) {
    width: 100%;
    padding: 30px 20px;
  }

  @media (max-width: 375px) {
    padding: 30px 10px;
    width: 100%;
    height: 80%;
  }
`

export const InputStyled = styled.input`
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 40px;
  font-size: 16px;
  color: ${variables.gray};
  padding: 20px 45px 20px 20px;

  &::placeholder {
    color: ${variables.white};
    padding-left: 5px;
  }

  @media (max-width: 375px) {
    font-size: 14px;
  }
`

export const InputBox = styled.div`
  position: relative;
  width: 100%;
  height: 50px;
  margin: 20px 0;

  @media (max-width: 490px) {
    margin: 30px 0;
  }
`

export const ShowPassword = styled.div`
  display: flex;
  justify-content: end;
  font-size: 14.5px;
  margin-top: 5px;
  margin-bottom: 20px;
  margin-right: 20px;
  color: ${variables.white};
`

export const Check = styled.input`
  accent-color: ${variables.white};
  margin-right: 5px;
`
export const EntryButton = styled.button`
  position: relative;
  width: 100%;
  height: 50px;
  background: ${variables.white};
  border: none;
  outline: none;
  border-radius: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  font-weight: 600;
  color: #333;
  cursor: pointer;

  &:hover {
    background-color: ${variables.white};
    color: ${variables.purple};
    background: ${variables.white};
    border: 2px solid ${variables.purple};
  }

  @media (max-width: 490px) {
    margin-top: 30px;
  }

  @media (max-width: 375px) {
    margin-top: 5px;
  }
`

export const ScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 32px;
  height: 80%;

  @media (max-width: 500px) {
    max-width: 250px;
    width: 100%;
    gap: 10px;
  }
`

export const BoxType = styled.div`
  max-width: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${variables.whiteSmoke};
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  h1 {
    font-size: 32px;
  }

  @media (max-width: 500px) {
    max-width: 250px;
    width: 100%;
  }
`

export default GlobalStyle
