import styled from 'styled-components'

export const Image = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
    height: 390px;
    margin-bottom: 10px;

    background-size: cover;
  }
`

export const Chair = styled.img`
  width: 50%;

  @media (max-width: 768px) {
    width: 250px;
    margin-bottom: 10px;
    background: transparent;
  }
`
export const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10%;

  @media (max-width: 768px) {
    margin: 5px;
  }
`
