import { useModal } from 'src/contexts/Modal/ModalContext'
import * as S from './styles'

const Modal: React.FC = () => {
  const { isOpen, content, closeModal } = useModal()

  if (!isOpen) {
    return null
  }

  return (
    <S.ModalOverlay onClick={closeModal}>
      <S.ModalContent onClick={(e) => e.stopPropagation()}>
        <S.CloseButton onClick={closeModal}>fechar</S.CloseButton>
        {content}
      </S.ModalContent>
    </S.ModalOverlay>
  )
}

export default Modal
