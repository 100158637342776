import { CustomizedSubtitle } from "./styles"

interface SubtitleProps {
  children?: React.ReactNode;
}

const Subtitle = ({ children }: SubtitleProps) => {
  return (
    <CustomizedSubtitle>{children} </CustomizedSubtitle>
  )
}

export default Subtitle
