import { User } from 'firebase/auth'
import { createContext } from 'react'

export type AuthContextType = {
  user: User | null
  accessToken: string | null
  refreshToken: () => void
  signin: (email: string, password: string) => Promise<boolean>
  signout: () => void
  signup: (name: string, email: string, password: string) => Promise<boolean>
}

export const AuthContext = createContext<AuthContextType>(null!)
