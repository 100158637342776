import variables from 'src/styles/variables'
import styled, { keyframes } from 'styled-components'

const fadeInOut = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 80px;

  animation: ${fadeInOut} 0.3s ease-in-out;
`

export const ModalContent = styled.div`
  background: ${variables.whiteSmoke};
  padding: 30px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  position: relative;
`

export const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  font-size: 16px;
  font-weight: 700;
  padding: 5px;
  cursor: pointer;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;

  &:hover {
    color: ${variables.purple};
    border: 1px solid ${variables.purple};
    border-radius: 5px;
  }
`
