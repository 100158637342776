import { Link } from 'react-router-dom'
import variables from 'src/styles/variables'
import styled from 'styled-components'

export const Welcome = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin-right: 32px;

  h1 {
    font-size: 48px;
    margin: 10px 0;
  }

  h3 {
    font-size: 16px;
    color: #718093;
  }

  @media (max-width: 800px) {
    margin-right: 0;
    align-items: center;

    h1 {
      margin-bottom: 10px;
    }

    h3 {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 490px) {
    h1 {
      font-size: 32px;
    }

    h3 {
      font-size: 14px;
    }
  }
`

export const Register = styled.div`
  font-size: 12px;
  text-align: end;
  margin: 20px 0;
`
export const LinkTo = styled(Link)`
  color: #353b48;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
    color: ${variables.gray};
  }

  @media (max-width: 375px) {
    font-size: 10px;
    margin-top: 5px;
  }
`
export const SocialMedia = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  p {
    margin-right: 10px;
    color: #718093;
    font-weight: bold;
  }
`
export const IconSocial = styled.button`
  width: 40px;
  height: 40px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover {
    background: ${variables.white};
    border-radius: 50%;
  }
`
export const Icons = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`
