import { useContext } from 'react'
import * as S from './styles'
import { CardContext } from 'src/contexts/Card/CardContext'
import { useModal } from 'src/contexts/Modal/ModalContext'
import Reschedule from '../Reschedule'
import Schedule from '../Schedule'
import { AutoCompleteName } from '../AutoCompleteName'
import { MemberProvider } from 'src/contexts/Members/MembersProvider'
import { SetupProvider } from 'src/contexts/Setups/SetupProvider'

const Card = () => {
  const { card } = useContext(CardContext) || {}
  const { openModal } = useModal()

  const handleDetailsClick = (classDetails: { aluno: string }[] | string) => {
    if (typeof classDetails === 'string') {
      openModal(
        <S.DetailsContainer>
          <S.ClassDetailsString>
            <p>{classDetails}</p>
            <S.ActionButton onClick={handleAddParticipantClick}>
              Adicionar aluno
            </S.ActionButton>
          </S.ClassDetailsString>
        </S.DetailsContainer>
      )
    } else if (Array.isArray(classDetails)) {
      const detailsString = classDetails
        .sort((a, b) => a.aluno.localeCompare(b.aluno))
        .map((detail, index) => (
          <S.DetailItem key={index}>
            <S.Content>
              <S.Aluno>Aluno:</S.Aluno>
              <p>{detail.aluno}</p>
            </S.Content>
            <S.ActionButton onClick={() => handleRescheduleClick(detail.aluno)}>
              Reagendar
            </S.ActionButton>
          </S.DetailItem>
        ))
      openModal(<S.DetailsContainer>{detailsString}</S.DetailsContainer>)
    } else {
      openModal(
        <S.DetailsContainer>
          <p>Erro ao carregar detalhes da aula</p>
        </S.DetailsContainer>
      )
    }
  }

  const handleRescheduleClick = (aluno: string | string) => {
    openModal(
      <S.DetailsContainer>
        <Reschedule aluno={aluno} />
      </S.DetailsContainer>
    )
  }

  const handleAddParticipantClick = () => {
    openModal(
      <S.DetailsContainer>
        <>
          <MemberProvider>
            <AutoCompleteName />
          </MemberProvider>
          <SetupProvider>
            <Schedule />
          </SetupProvider>
        </>
      </S.DetailsContainer>
    )
  }

  return (
    <>
      {card &&
        card.map(({ time, class: className, classDetails }) => (
          <S.Card key={time}>
            <h3>{time}</h3>
            <h4>{className}</h4>
            <S.DetailsButton
              onClick={() =>
                handleDetailsClick(classDetails as { aluno: string }[] | string)
              }
            >
              Detalhes
            </S.DetailsButton>
          </S.Card>
        ))}
    </>
  )
}

export default Card
