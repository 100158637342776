import { EntryButton } from "./styles";

interface ButtonProps {
  children?: React.ReactNode;
  onClick?: () => void;
}

const Button = ({ children, onClick }: ButtonProps) => {

  return (
    <EntryButton onClick={onClick}>{children}</EntryButton>
  );
}

export default Button;
