import { useContext } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './datePickerStyles.css'
import { ptBR } from 'date-fns/locale'
import * as S from './styles'
import { BoxType, ScheduleContainer } from 'src/styles'
import { useDate } from '../../contexts/Date/DateContext'
import SessionContext from 'src/contexts/Sessions/SessionContext'

const Schedule: React.FC = () => {
  const { selectedDate, setSelectedDate } = useDate()
  const { sessions } = useContext(SessionContext)

  const handleDateChange = (date: Date | null) => {
    if (date) {
      const adjustedDate = new Date(date.setHours(0, 0, 0, 0))
      setSelectedDate(adjustedDate)
    } else {
      setSelectedDate(new Date())
    }
  }

  const selectedDateString = selectedDate.toISOString().split('T')[0]

  const availableHours = sessions
    .filter((session) => session.date === selectedDateString)
    .map((session) => session.slot_start)
    .sort((a, b) => a.localeCompare(b))

  return (
    <>
      <ScheduleContainer>
        <BoxType>
          <S.Label htmlFor="date">Escolha o dia:</S.Label>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            id="date"
            dateFormat="dd/MM/yyyy"
            locale={ptBR}
            className="date-picker"
          />
          <S.Hour>
            <S.Label htmlFor="hour" id="hour">
              Escolha o horário disponível:
            </S.Label>
            <select name="hour" id="hour">
              {availableHours.map((hour, index) => (
                <option key={index} value={hour}>
                  {hour}
                </option>
              ))}
            </select>
          </S.Hour>
        </BoxType>
        <S.SubmitButton>Agendar</S.SubmitButton>
      </ScheduleContainer>
    </>
  )
}

export default Schedule
