import variables from 'src/styles/variables'
import styled from 'styled-components'

export const SetupContainer = styled.div`
  max-width: 900px;
  margin: auto;
  margin-top: 50px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin-bottom: 20px;

  h3 {
    text-align: center;
    margin-bottom: 20px;
  }


  @media (max-width: 768px) {
    max-width: 600px;
`
export const InformationContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-bottom: 20px;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`
export const Hours = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 20px;

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  h5 {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 16px;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const DivButton = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;

  button {
    width: 100px;
    border-radius: 8px;
    font-size: 12px;
    padding: 5px;
    cursor: pointer;

    &:hover {
      font-weight: bold;
      color: ${variables.white};
      background-color: ${variables.darkGray};
    }
  }
`
