import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../Auth/AuthContext'
import Api from 'src/providers/Api'
import { Setup } from 'src/Types/setup'
import SetupContext from './SetupContext'

interface SetupProviderProps {
  children: React.ReactNode
}

export const SetupProvider: React.FC<SetupProviderProps> = ({
  children
}: SetupProviderProps) => {
  const auth = useContext(AuthContext)
  const [setups, setSetups] = useState<Setup[]>([])
  const [businessId, setBusinessId] = useState<string | null>(null)

  const accessToken = auth.accessToken

  const fetchSetups = async () => {
    try {
      const response = await Api.get('setups', {
        headers: {
          Authorization: `${accessToken}`,
          'Content-Type': 'application/json'
        }
      })

      if (response.data) {
        setBusinessId(response.data.business_id)
        const data = Array.isArray(response.data)
          ? response.data
          : [response.data]
        setSetups(data)
      } else {
        console.error('Response data is invalid:', response.data)
        setSetups([])
      }
    } catch (error) {
      console.error('Failed to fetch setups', error)
      setSetups([])
    }
  }

  useEffect(() => {
    fetchSetups()
  }, [])

  const addSetup = async (setup: Setup) => {
    try {
      const response = await Api.post('/setups', setup, {
        headers: {
          Authorization: `${accessToken}`,
          'Content-Type': 'application/json'
        }
      })

      if (response.status === 201) {
        setSetups((prevSetups) => [...prevSetups, setup])
        fetchSetups()
      } else {
        console.error('Failed to add setup', response.statusText)
      }
    } catch (error) {
      console.error('Failed to add setup', error)
    }
  }

  const updateSetup = async (setup: Setup) => {
    try {
      const response = await Api.put('/setups', setup, {
        headers: {
          Authorization: `${accessToken}`,
          'Content-Type': 'application/json'
        }
      })

      if (response.status === 201) {
        // setSetups((prevSetups) => [...prevSetups, setup])
        // fetchSetups()
        console.log('response', response)
      } else {
        console.error('Failed to update setup', response.statusText)
      }
    } catch (error) {
      console.error('Failed to update setup', error)
    }
  }

  return (
    <SetupContext.Provider
      value={{ setups, businessId, fetchSetups, addSetup, updateSetup }}
    >
      {children}
    </SetupContext.Provider>
  )
}
