/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useState } from 'react'
import {
  // IoLogoGoogle,
  IoPersonOutline,
  IoLockClosedOutline
} from 'react-icons/io5'
import {
  FormContainer,
  MainContainer,
  InputStyled,
  InputBox,
  ShowPassword,
  Check,
  EntryButton
} from 'src/styles'
import * as S from './styles'
import { AuthContext } from 'src/contexts/Auth/AuthContext'
import { useNavigate } from 'react-router-dom'

import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

const Login = () => {
  const auth = useContext(AuthContext)
  const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const handleViewPassword = () => {
    setShowPassword(!showPassword)
  }

  const [notification, setNotification] = useState<{
    open: boolean
    message: string
    severity: 'success' | 'error' | 'info' | 'warning'
  }>({
    open: false,
    message: '',
    severity: 'success'
  })

  const handleCloseNotification = (
    _event: Event | React.SyntheticEvent<unknown, Event>,
    reason: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setNotification({ ...notification, open: false })
  }

  const handleLogin = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()

    if (email && password) {
      try {
        const isLogged = await auth.signin(email, password)
        if (isLogged) {
          navigate('/dashboard')
        }
      } catch (error) {
        if ((error as any).code === 'auth/user-not-found') {
          setNotification({
            open: true,
            message: 'Usuário não encontrado',
            severity: 'info'
          })
        } else if ((error as any).code === 'auth/invalid-credential') {
          setNotification({
            open: true,
            message: 'Usuário ou Senha inválida',
            severity: 'info'
          })
        } else {
          setNotification({
            open: true,
            message: 'Erro ao fazer login',
            severity: 'error'
          })
        }
      }
    }
  }

  return (
    <>
      <MainContainer>
        <S.Welcome>
          <h1>Faça o Login</h1>
          <h3>Seja bem-vindo de volta!</h3>
        </S.Welcome>
        <div>
          <FormContainer>
            <InputBox>
              <S.Icons>
                <IoPersonOutline />
              </S.Icons>
              <InputStyled
                id="email"
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Digite seu E-mail"
                autoComplete="email"
                required
              />
            </InputBox>
            <InputBox>
              <S.Icons>
                <IoLockClosedOutline />
              </S.Icons>
              <InputStyled
                id="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Senha"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </InputBox>
            <ShowPassword>
              <Check
                checked={showPassword}
                onChange={handleViewPassword}
                type="checkbox"
                id="check"
              />
              <label htmlFor="check">Mostrar Senha</label>
            </ShowPassword>
            <EntryButton onClick={handleLogin} type="submit">
              Entrar
            </EntryButton>
            <S.Register>
              Não tem uma conta? <S.LinkTo to="/register">Registre-se</S.LinkTo>
            </S.Register>
            <S.Register>
              <S.LinkTo to="/resetPassword">Esqueci minha senha</S.LinkTo>
            </S.Register>
          </FormContainer>
          {/* <S.SocialMedia>
            <p>Ou conecte-se com o Google:</p>
            <S.IconSocial>
              <IoLogoGoogle
                style={{
                  width: 'auto',
                  height: '80%',
                  borderRadius: '50%',
                  color: '#db4a39'
                }}
              />
            </S.IconSocial>
          </S.SocialMedia> */}
        </div>
      </MainContainer>
      <Snackbar
        open={notification.open}
        autoHideDuration={3000}
        onClose={(event, reason) => handleCloseNotification(event, reason)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() =>
            handleCloseNotification(
              {} as React.SyntheticEvent<unknown, Event>,
              ''
            )
          }
          severity={notification.severity}
        >
          {notification.message}
        </MuiAlert>
      </Snackbar>
    </>
  )
}

export default Login
