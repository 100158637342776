import { createContext, useContext } from 'react'
import { Setup } from 'src/Types/setup'

interface SetupContextProps {
  setups: Setup[]
  businessId: string | null
  fetchSetups: () => void
  addSetup: (setup: Setup) => void
  updateSetup: (setup: Setup) => void
}

const SetupContext = createContext<SetupContextProps | undefined>(undefined)

export const useSetupContext = (): SetupContextProps => {
  const context = useContext(SetupContext)
  if (!context) {
    throw new Error('useSetupContext must be used within a SetupProvider')
  }
  return context
}

export default SetupContext
