import variables from 'src/styles/variables'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 56px;

  h2 {
    height: 40px;
    max-width: 300px;
    width: 100%;
    border: none;
    border-radius: 8px;
    padding: 0 16px;
    background-color: ${variables.whiteSmoke};
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;

    @media (max-width: 500px) {
      max-width: 250px;
      width: 100%;
    }
  }
`
