/* eslint-disable no-useless-catch */
import axios from 'axios'

const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken')
  if (!refreshToken) {
    throw new Error('No refresh token found')
  }

  try {
    const response = await axios.post(
      'https://api.agendacompleta.com/auth/refresh',
      {
        refresh_token: refreshToken
      }
    )

    if (response.data && response.data.access_token) {
      localStorage.setItem('accessToken', response.data.access_token)
      return response.data.access_token
    }

    throw new Error('Failed to refresh access token')
  } catch (error) {
    throw error
  }
}

const Api = axios.create({
  baseURL: 'https://api.agendacompleta.com',
  timeout: 10000
})

Api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      try {
        const newAccessToken = await refreshAccessToken()
        error.config.headers['Authorization'] = `Bearer ${newAccessToken}`
        return Api.request(error.config)
      } catch (refreshError) {
        throw refreshError
      }
    }
    return Promise.reject(error)
  }
)

export default Api
