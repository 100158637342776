import styled from "styled-components";

export const CustomizedSubtitle = styled.h5`
  font-size: 20px;
  color: #8395a7;
  padding-top: 20px;
  padding-bottom: 20px;

  @media (max-width: 1230px) {
    text-align: center;
  }

  @media (max-width: 770px) {
    font-size: 16px;
  }
`
