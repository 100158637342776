import styled, { keyframes } from 'styled-components'
import { Link } from 'react-router-dom'
import variables from 'src/styles/variables'

interface NavProps {
  $isOpen: boolean
}

interface LinkProps {
  $isActive: boolean
}

const fadeInOut = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

export const Container = styled.header`
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  p {
    font-size: 10px;
    margin-left: 20px;
    color: #535c68;
    font-weight: bold;
    font-style: italic;
  }

  @media (max-width: 768px) {
    p {
      font-size: 8px;
    }
  }
`

export const Logo = styled.img`
  width: 60px;
`

export const Nav = styled.nav<NavProps>`
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  padding: 0;

  ul {
    display: flex;
    flex-direction: ${(props) => (props.$isOpen ? 'column' : 'row')};
    gap: 20px;
  }

  @media (max-width: 768px) {
    margin-top: 10px;

    p {
      font-size: 10px;
    }

    ul {
      flex-direction: column;
      position: absolute;
      margin-right: 36px;
      top: 40px;
      background-color: ${variables.purple};
      gap: 20px;
      border: 1px solid ${variables.purple};
      border-radius: 8px;
      width: 50%;
      align-items: center;
      padding: 25px;

      animation: ${fadeInOut} 0.3s ease-in-out;
    }
  }
`

export const LinkTo = styled(Link)<LinkProps>`
  color: ${variables.purple};
  font-size: 12px;
  text-decoration: ${(props) => (props.$isActive ? 'underline' : 'none')};
  font-weight: ${(props) => (props.$isActive ? 'bold' : 'normal')};

  &:hover {
    color: ${variables.blueMagenta};
    background-color: ${variables.gray};
  }

  @media (max-width: 768px) {
    font-size: 20px;
    color: ${variables.white};
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      color: ${variables.blueMagenta};
      background-color: ${variables.gray};
      text-decoration: none;
    }
  }
`

export const Hr = styled.hr`
  height: 3px;
  border: 0;
  background: linear-gradient(
      to left,
      ${variables.blueMagenta} 0%,
      ${variables.blueMagenta} 50%,
      transparent 98%
    ),
    linear-gradient(
      to right,
      ${variables.blueMagenta} 0%,
      ${variables.blueMagenta} 50%,
      transparent 98%
    );
  background-size: 50% 100%;
  background-position: left top, right top;
  background-repeat: no-repeat;
`
export const ContentMobile = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
  }
`
