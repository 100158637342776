import Schedule from '../Schedule'
import * as S from './styles'

type RescheduleProps = {
  aluno: string | string[]
}

const Reschedule = ({ aluno }: RescheduleProps) => {
  return (
    <>
      <S.Container>
        <h2>{aluno}</h2>
      </S.Container>
      <Schedule />
    </>
  )
}

export default Reschedule
