import { useEffect, useState } from 'react'
import { AuthContext } from './AuthContext'
import {
  User,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword
} from 'firebase/auth'
import { auth } from '../config'
import Api from 'src/providers/Api'

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [user, setUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)
  const [accessToken, setAccessToken] = useState<string | null>(null)

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user)
        user.getIdToken().then((token) => setAccessToken(token))
      } else {
        setUser(null)
        setAccessToken(null)
      }
      setLoading(false)
    })
    return () => unsubscribe()
  }, [])

  const refreshToken = async () => {
    const token = await user?.getIdToken(true)
    if (token) {
      setAccessToken(token)
    }
  }

  const signin = async (email: string, password: string) => {
    const data = await signInWithEmailAndPassword(auth, email, password)

    if (data) {
      return true
    }
    return false
  }

  const signout = async () => {
    await auth.signOut()
    setUser(null)
    setAccessToken(null)
  }

  const signup = async (name: string, email: string, password: string) => {
    const data = await createUserWithEmailAndPassword(auth, email, password)

    if (data) {
      const payload = {
        name: name,
        provider_code: data.user.uid,
        email: email
      }
      const response = await Api.post('register', payload)
      console.log(response.status)
      return true
    }

    return false
  }

  if (loading) {
    return <div>Carregando...</div>
  }

  return (
    <>
      <AuthContext.Provider
        value={{ user, accessToken, refreshToken, signin, signout, signup }}
      >
        {children}
      </AuthContext.Provider>
    </>
  )
}
