import { BoxType } from 'src/styles'
import variables from 'src/styles/variables'
import styled from 'styled-components'

export const CustomBoxType = styled(BoxType)`
  display: block;
  margin-top: 20px;
`

export const InputName = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid ${variables.gray};
  border-radius: 5px;
`
export const InputPhone = styled.input`
  display: flex;
  justify-content: flex-start;
  max-width: 200px;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid ${variables.gray};
  border-radius: 5px;
`
