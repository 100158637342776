import { Container } from '@mui/material'
import Header from 'src/components/Header'
import SetupCard from 'src/components/SetupCard'
import SetupItens from 'src/components/SetupItens'
import { useSetupContext } from 'src/contexts/Setups/SetupContext'
import { SetupProvider } from 'src/contexts/Setups/SetupProvider'
import { useState, useEffect } from 'react'

const Setup = () => {
  const { setups } = useSetupContext()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (setups.length !== 0) {
      setLoading(false)
    }
  }, [setups])

  return (
    <>
      <Header />
      <Container>
        {loading ? (
          <p>Carregando...</p>
        ) : setups[0]?.blueprint === null ? (
          <SetupCard />
        ) : (
          <SetupItens />
        )}
      </Container>
    </>
  )
}

const SetupWrapper = () => {
  return (
    <SetupProvider>
      <Setup />
    </SetupProvider>
  )
}

export default SetupWrapper
