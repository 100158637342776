import GlobalStyle from './styles'

import { AppRoutes } from './routes/AppRoutes'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

function App() {
  const client = new QueryClient()
  return (
    <>
      <QueryClientProvider client={client}>
        <GlobalStyle />
        <AppRoutes />
      </QueryClientProvider>
    </>
  )
}

export default App
