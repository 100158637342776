import variables from 'src/styles/variables'
import styled from 'styled-components'

export const MembersListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
`

export const MembersTitle = styled.h2`
  color: ${variables.darkGray};
  font-size: 24px;
  margin-bottom: 16px;
`
export const Table = styled.table`
  width: 800px;
  border-collapse: collapse;
  margin-bottom: 16px;
  border: 1px solid ${variables.darkGray};
  text-align: left;

  th {
    background-color: ${variables.darkGray};
    color: ${variables.white};
    width: auto;
  }
  td {
    padding: 5px;
  }
`

export const Button = styled.button`
  background-color: ${variables.darkGray};
  color: ${variables.white};
  border: none;
  padding: 5px 5px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${variables.gray};
    color: ${variables.darkGray};
  }
`
